import * as React from 'react'
import { Link } from 'gatsby'
import { mainStyle, siteNav, navItem } from './layout.module.css'
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ pageTitle, children }) => {
  return (
    <main className={mainStyle}>
      <title>{pageTitle}</title>
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      <nav className={siteNav}>
          <Link to="/">
            <StaticImage src="../images/tay.png"
                alt="TAY Global LTD"
                layout="fixed"
                width={60}
                height={50} />
          </Link>
          <Link to="/" className={navItem}>Home</Link>
          <Link to="/about" className={navItem}>About</Link>
      </nav>
      <h1>{pageTitle}</h1>
      {children}
    </main>
  )
}

export default Layout