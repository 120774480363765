import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from "gatsby-plugin-image"
import { linkedin } from '../components/layout.module.css'

const AboutPage = () => {
  return (
    <Layout pageTitle="About TAY Global LTD">
      <p>There was a time when I would offer consultation in Data Science, Machine Learning or Crypto trading.</p>
      <p>However that time has passed. Thanks for visiting</p>
      <p>
        <StaticImage src="../images/linkedin.png"
          alt="LinkedIn"
          layout="fixed"
          width={20}
          height={20}
          className={linkedin} />
        <a href='https://www.linkedin.com/in/tonyyum/' target='_black'>Linked in</a>
      </p>
    </Layout>
  )
}

export default AboutPage